import React, { PropsWithChildren } from 'react'
import Container from '@components/Container'
import SEO from '@components/SEO'
import { useTranslation } from 'react-i18next'
import { ProCoursePageFragmentFragment } from '@graphql-types'
import CoursePRONav from './_nav'
import CourseMode from '@components/CourseMode'

export type Course = NonNullable<ProCoursePageFragmentFragment['course']>

const PROLayout = ({
	course,
	children,
}: PropsWithChildren<{
	course: Course
}>) => {
	const { t } = useTranslation()
	return (
		<Container>
			<SEO title={course.name} keywords={[]} />
			<CourseMode mode={course!.mode!} />
			<h1>{course!.name}</h1>
			{/* <CoursePRONav course={course} /> */}
			{children}
		</Container>
	)
}

export default PROLayout
