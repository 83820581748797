import { css, useTheme } from '@emotion/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CourseMode = (props: { mode: string, isInline?: boolean }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div
      css={css`
				display: ${props.isInline ? 'inline-block' : 'block'};
				width: ${props.isInline ? 'auto' : '10rem'};
				padding: ${props.isInline ? '0.2rem 0.6rem' : '0.2rem'};
				text-align: center;
				margin: ${props.isInline ? '0 0.4rem 0 0' : '0 auto 0.8rem'};
				border-radius: 0.4rem;
				background-color: ${props.mode == 'full-time'
          ? theme.fullTimeColor
          : props.mode == 'self-paced'
            ? theme.selfPacedColor
            : props.mode == 'free' || props.mode == 'workshop'
              ? theme.freeColor
              : theme.partTimeColor};
			`}>
      {props.mode == 'full-time' && t('layout:full_time')}
      {props.mode == 'self-paced' && t('layout:self_paced')}
      {props.mode == 'night-time' && t('layout:part_time')}
      {props.mode == 'workshop' && t('layout:workshop')}
      {props.mode == 'free' && t('layout:free')}
    </div>
  )
}

export default CourseMode
