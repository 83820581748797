import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { ProInstructorsQuery } from '@graphql-types'
import { css } from '@emotion/react'
import StaffComponent from '@components/StaffComponent'
import PROLayout from './_layout'
import { StaffWrap } from '@components/StaffWrap'

const PROInstructorsPage = (props: PageProps<ProInstructorsQuery>) => {
	const { t } = useTranslation()
	const course = props.data.course!
	return (
		<PROLayout course={course}>
			<h2>{t('courses:common.instructor_team')}</h2>
			<p>{t('instructor_description')}</p>
			<StaffWrap>
				{course?.instructors?.map((instructor, i) => (
					<StaffComponent key={i} staff={instructor}></StaffComponent>
				))}
			</StaffWrap>
		</PROLayout>
	)
}

export default PROInstructorsPage

export const query = graphql`
	query ProInstructors($language: String!, $id: String!) {
		...TranslationFragment
		...ProCoursePageFragment
	}
`
