import styled from '@emotion/styled'
import { minWidth, responsiveColumn } from 'src/themes'

export const StaffWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	${responsiveColumn};

	${minWidth[2]} {
		> div {
			width: calc(50% - 1rem);
		}
	}
`
